import './assets/style.scss'
import bgImage from './assets/images/bg/andre-benz-cXU6tNxhub0-unsplash.jpg'

const setAge = () => {
  let birthday = new Date('1980-07-01')
  let now = new Date()
  const diffTime = Math.abs(now - birthday)
  const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365))
  let el = document.getElementById('age')
  el.innerText = diffYears
}

const setView = hash => {
  let target = '#about'
  if (hash && document.querySelector(hash)) {
    target = hash
  }

  document.body.id = 'page-' + target.substring(1)
  document.querySelectorAll('.section').forEach(el => {
    if (el.id === target.substring(1)) {
      el.classList.remove('hide')
    } else {
      el.classList.add('hide')
    }
  })
  document.querySelectorAll('.nav a').forEach(el => {
    if (el.href.substring(el.href.indexOf('#')) === target) {
      el.classList.add('active')
    } else {
      el.classList.remove('active')
    }
  })
}

const setBackground = () => {
  let bg = document.getElementById('body-bg')

  let preloaderImg = document.createElement('img')
  preloaderImg.src = bgImage

  preloaderImg.addEventListener('load', event => {
    console.log('image loaded')
    bg.style.backgroundImage = `url(${bgImage})`
    setTimeout(() => {
      bg.classList.add('show')
      document.querySelector('.badge').classList.add('show')
    }, 1000)
    preloaderImg = null
  })
}

document.addEventListener('DOMContentLoaded', event => {
  setAge()

  setView(document.location.hash)
  window.onhashchange = event => {
    setView(document.location.hash)
    window.scrollTo(0, 0)
  }

  if (!/mobi/i.test(navigator.userAgent)) {
    setBackground()
  }
})
